import React, { useEffect, useState } from 'react';
import styles from './style/Dashboard.module.scss';
import Modal from '../Utilities/Modal';
import Table from '../Utilities/Table';
import Button from '../Utilities/Button';
import Paginate from '../Utilities/Paginate';
import { MdClear } from 'react-icons/md';
import EmptyBox from '../Utilities/EmptyBox';
import { useDispatch, useSelector } from 'react-redux';
import { getSubscriptionHistory, selectEnterpriseCompany } from '../../store/slice/manager/enterpriseCompany';
import LoadingTable from '../Utilities/LoadingTable';

const ModalCreatePlan = ({ isOpen, setIsOpen, enterpriseId, serviceId, setEnterpriseId, setServiceId }) => {
  const headerData = [
    {
      title: '扣款日期',
      width: 10,
    },
    {
      title: '更換日期',
      width: 10,
    },
    {
      title: '月租方案',
      width: 15,
    },
    {
      title: '贈送人數',
      width: 8,
    },
    {
      title: '綁約天數',
      width: 8,
    },
    {
      title: '開始日期',
      width: 10,
    },
    {
      title: '結束日期',
      width: 10,
    },
    {
      title: '付款方式',
      width: 12,
    },
    {
      title: '付款金額',
      width: 7,
    },
    {
      title: '付款狀態',
      width: 12,
    },
  ];

  const dispatch = useDispatch();
  const { history, loading } = useSelector(selectEnterpriseCompany);
  const [page, setPage] = useState(1);
  const limit = 15;

  useEffect(() => {
    if (enterpriseId) {
      const postObj = {
        enterprise_id: enterpriseId,
        service_id: serviceId,
        page: 1,
        limit: limit,
      };
      dispatch(getSubscriptionHistory(postObj));
    }
  }, [enterpriseId, serviceId, dispatch]);

  const pageChangeHandler = (e) => {
    setPage(parseInt(e.selected + 1));
    const postObj = {
      enterprise_id: enterpriseId,
      service_id: serviceId,
      page: e.selected + 1,
      limit: limit,
    };
    dispatch(getSubscriptionHistory(postObj));
  };

  return (
    <Modal isOpen={isOpen} size='xl'>
      <div className={styles.ModalContainer}>
        <div className={`${styles.modalHeader} ${styles.special}`}>
          <div className={styles.modalTitle}>訂閱購買紀錄</div>
          <Button
            type='tertiary'
            icon={<MdClear />}
            onClick={() => {
              setIsOpen(false);
              setEnterpriseId('');
              setServiceId('');
              setPage(1);
            }}
          />
        </div>
        <div className={`${styles.modalBody} ${styles.nonOverflow}`}>
          <Table header={headerData}>
            {loading ? (
              <LoadingTable row={10} column={8} />
            ) : (
              <>
                {history?.rows?.length > 0 ? (
                  <tbody>
                    {history?.rows?.map((data, index) => (
                      <tr key={index}>
                        <td width={`${headerData[0].width}%`}>{data.created_at}</td>
                        <td width={`${headerData[1].width}%`}>{data.change_date ? data.change_date : '-'}</td>
                        <td width={`${headerData[2].width}%`}>
                          <span className={styles.textPrimary}>{data.service_plan_name}</span>
                        </td>
                        {/* <td width={`${headerData[3].width}%`}>{data.additional_quantity === 1 ? 0 : data.additional_quantity}</td> */}
                        <td width={`${headerData[3].width}%`}>{data.gift_quantity ? data.gift_quantity + ' 人' : '-'}</td>
                        <td width={`${headerData[4].width}%`}>{data.validity_days}</td>
                        <td width={`${headerData[5].width}%`}>{data.start_date}</td>
                        <td width={`${headerData[6].width}%`}>{data.expiration_date}</td>
                        <td width={`${headerData[7].width}%`}>{data.payment_method}</td>
                        <td width={`${headerData[8].width}%`}>
                          <span className={styles.textPrimary}>
                            {data.payment_amount > 0 ? 'NT$' + data.payment_amount?.toLocaleString() : <b>Free</b>}
                          </span>
                        </td>
                        <td width={`${headerData[9].width}%`}>
                          {data.payment_succeeded ? (
                            <span className={styles.success}>付款成功</span>
                          ) : (
                            <span className={styles.failed}>付款失敗</span>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                ) : (
                  <tbody style={{ height: '450px' }}>
                    <tr>
                      <td colSpan={8} style={{ width: '100%', display: 'block' }}>
                        <EmptyBox type={1} caption={'目前還未有任何訂閱購買紀錄'} />
                      </td>
                    </tr>
                  </tbody>
                )}
              </>
            )}
          </Table>
        </div>
        <div className={`${styles.modalFooter} ${styles.normal}`}>
          {history?.rows?.length > 0 && (
            <Paginate
              onPageChange={pageChangeHandler}
              pageCount={page}
              limit={limit}
              totalPages={Math.ceil(history?.total / limit)}
              totalData={history?.total}
            />
          )}
        </div>
      </div>
    </Modal>
  );
};

export default ModalCreatePlan;
