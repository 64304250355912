import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { sendGetClientRequest, sendPutClientRequest, sendPostClientRequest } from '../../../api/helper';
import { serialize } from '../../../utils';

// 贈送帳號
export const getGift = createAsyncThunk('enterpriseCompany/getGift', async (parameters) => {
  const response = await sendGetClientRequest(`/api/enterprise_management/${parameters.enterprise_id}/gift?service_id=${parameters.service_id}`);
  return response;
});

export const postGift = createAsyncThunk('enterpriseCompany/postGift', async (parameters) => {
  const postObj = {
    service_id: parameters.service_id,
    quantity: parameters.quantity,
    start_date: parameters.start_date,
    expiration_date: parameters.expiration_date,
  };
  const response = await sendPostClientRequest(`/api/enterprise_management/${parameters.enterprise_id}/gift`, postObj);
  return response;
});

export const getEnterpriseCompany = createAsyncThunk('enterpriseCompany/getEnterpriseCompany', async (parameters) => {
  const queryObj = {};
  if (parameters) {
    if (parameters.limit) queryObj.limit = parameters.limit;
    if (parameters.currentPage) queryObj.currentPage = parameters.currentPage;
    if (parameters.keyword) queryObj.keyword = parameters.keyword;
  }
  let queryString = '';
  if (JSON.stringify(queryObj) !== '{}') queryString = '?' + serialize(queryObj);

  const response = await sendGetClientRequest(`/api/enterprise_management/subscribe_services/${parameters.service_id}${queryString}`);
  return response.data;
});

export const activateEnterpriseCompany = createAsyncThunk('enterpriseCompany/activateEnterpriseCompany', async (parameters) => {
  const postObj = {
    activation_setting: parameters.activation_setting,
    service_id: parameters.service_id,
  };
  const response = await sendPutClientRequest(`/api/enterprise_management/${parameters.enterprise_id}/activation_state`, postObj);
  return response;
});

export const getSubscriptionHistory = createAsyncThunk('enterpriseCompany/getSubscriptionHistory', async (parameters) => {
  const postObj = {
    service_id: parameters.service_id,
    page: parameters.page,
    limit: parameters.limit,
  };
  const response = await sendPostClientRequest(`/api/enterprise_management/${parameters.enterprise_id}/subscription-history`, postObj);
  return response.data;
});

export const openTrialPlan = createAsyncThunk('enterpriseCompany/openTrialPlan', async (data) => {
  const response = await sendPostClientRequest(`/api/enterprise_management/${data.enterprise_id}/activate_trial`, data);
  return response;
});

export const removeCompany = createAsyncThunk('enterpriseCompany/removeCompany', async ({ enterprise_id }) => {
  const response = await sendPostClientRequest(`/api/enterprise_management/${enterprise_id}/delete`);
  return response;
});

const enterpriseCompanySlice = createSlice({
  name: 'enterpriseCompany',
  initialState: {
    business: [],
    isSuccess: false,
    loading: false,
    error: null,
    permission_error: false,
    history: '',
    gift: '',
    isGiftSuccess: false,
  },
  reducers: {
    removeSuccess: (state) => {
      state.isSuccess = null;
      state.isGiftSuccess = false;
      state.error = null;
    },
  },
  extraReducers: {
    // GET GIFT
    [getGift.pending]: () => {},
    [getGift.fulfilled]: (state, { payload }) => {
      if (payload?.data) state.gift = payload?.data;
    },
    [getGift.rejected]: () => {},

    // POST GIFT
    [postGift.pending]: (state) => {
      state.loading = true;
      state.isGiftSuccess = false;
    },
    [postGift.fulfilled]: (state, { payload }) => {
      if (payload.msg === 'success') {
        state.isGiftSuccess = true;
        state.loading = false;
        state.error = false;
      } else {
        state.loading = false;
        state.error = true;
      }
    },
    [postGift.rejected]: (state) => {
      state.loading = false;
    },

    // GET SUBSCRIPTION HISTORY
    [getSubscriptionHistory.pending]: (state) => {
      state.loading = true;
    },
    [getSubscriptionHistory.fulfilled]: (state, { payload }) => {
      if (payload) {
        state.history = payload;
        state.loading = false;
      }
    },
    [getSubscriptionHistory.rejected]: (state) => {
      state.loading = false;
    },

    // GET ENTERPRISE COMPANY
    [getEnterpriseCompany.pending]: (state) => {
      state.loading = true;
    },
    [getEnterpriseCompany.fulfilled]: (state, { payload }) => {
      if (payload) {
        state.business = payload;
        state.loading = false;
        state.permission_error = false;
      } else {
        state.loading = false;
        state.permission_error = true;
      }
    },
    [getEnterpriseCompany.rejected]: (state) => {
      state.loading = false;
    },

    // ACTIVATE ENTERPRISE COMPANY
    [activateEnterpriseCompany.pending]: (state) => {
      state.loading = true;
      state.isSuccess = false;
    },
    [activateEnterpriseCompany.fulfilled]: (state, { payload }) => {
      if (payload.msg === 'success') {
        state.isSuccess = true;
        state.loading = false;
        state.error = false;
      } else {
        state.loading = false;
        state.error = true;
      }
    },
    [activateEnterpriseCompany.rejected]: (state) => {
      state.loading = false;
    },
    // open Trial Plan
    [openTrialPlan.pending]: (state) => {
      state.loading = true;
    },
    [openTrialPlan.fulfilled]: (state, { payload }) => {
      if (payload.msg === 'success') {
        state.loading = false;
      }
    },
    [openTrialPlan.rejected]: (state) => {
      state.loading = false;
    },

    //remove company
    [removeCompany.pending]: (state) => {
      state.loading = true;
    },
    [removeCompany.fulfilled]: (state, { payload }) => {
      if (payload.msg === 'success') {
        state.loading = false;
      }
    },
    [removeCompany.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export default enterpriseCompanySlice.reducer;
export const selectEnterpriseCompany = (state) => state.enterpriseCompany;
export const { removeSuccess } = enterpriseCompanySlice.actions;
