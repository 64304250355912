import styles from './style/Dashboard.module.scss';
import InnerLayout from '../Layout/InnerLayout';
import AlertSwitch from '../Utilities/AlertSwitch';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { IoChevronDownOutline } from 'react-icons/io5';
import { AiOutlineSetting } from 'react-icons/ai';
import { RiHistoryLine } from 'react-icons/ri';
import Pagination from '../Utilities/PaginationNew';
import Toast from '../Utilities/Toast';
import Tabs from '../Utilities/Tabs';
import {
  activateEnterpriseCompany,
  getEnterpriseCompany,
  getGift,
  removeCompany,
  removeSuccess,
  selectEnterpriseCompany,
} from '../../store/slice/manager/enterpriseCompany';
import { selectPermissionSettings } from '../../store/slice/permissionSettings';
import { getDetail, selectMenu } from '../../store/slice/manager/menu';
import Alert from '../Utilities/Alert';
import EmptyBox from '../Utilities/EmptyBox';
import LoadingTable from '../Utilities/LoadingTable';
import Button from '../Utilities/Button';
import Tips from '../Utilities/Tips';
import InputSearch from '../Utilities/InputSearch';
import ModalHistory from './ModalHistory';
import TrialPlanModal from './TrialPlanModal';
import GiftModal from './GiftModal';
import { MdClear, MdPeople } from 'react-icons/md';
import moment from 'moment';
import { FiCheck } from 'react-icons/fi';
import { putVerifyEmail } from '../../store/slice/enterpriseLists';
import ModalUsers from '../ExecutiveDashboard/ModalUsers';
import { LuTrash } from 'react-icons/lu';
import TextField from '../Utilities/TextField';
import { generateRandomString } from '../../utils';

const DashboardBusiness = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { business, isSuccess, isGiftSuccess, error, loading, permission_error } = useSelector(selectEnterpriseCompany);

  const { detail } = useSelector(selectMenu);
  const { auth, permissions } = useSelector(selectPermissionSettings);

  const pageLimit = 10;

  const [searchTerm, setSearchTerm] = useState('');
  const [fetchSearchValue, setFetchSearchValue] = useState('');
  const [tabValue, setTabValue] = useState('');
  const [index, setIndex] = useState();
  const [status, setStatus] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [openDetail, setOpenDetail] = useState(false);
  const [enterpriseId, setEnterpriseId] = useState('');
  const [serviceId, setServiceId] = useState('');
  const [page, setPage] = useState(0);
  const [openTrialPlanModal, setOpenTrialPlanModal] = useState('');
  const [openTrialPlanPopup, setOpenTrialPlanPopup] = useState('');
  const [openGiftPopup, setOpenGiftPopup] = useState('');
  const [openGiftModal, setOpenGiftModal] = useState('');
  const [company, setCompany] = useState('');
  const [isOpenModalUser, setIsOpenModalUser] = useState(false);
  const [openRemove, setOpenRemove] = useState('');
  const [generateCode, setGenerateCode] = useState('');
  const [secretCode, setSecretCode] = useState('');
  const [errorCode, setErrorCode] = useState('');

  const [tabList] = useState(['企業帳號管理', '方案管理', '付費方式']);

  useEffect(() => {
    if (params.id) {
      dispatch(
        getEnterpriseCompany({
          service_id: params.id,
          currentPage: page + 1,
          limit: pageLimit,
          keyword: fetchSearchValue,
        })
      );
      dispatch(getDetail({ service: params.id }));
    }
  }, [dispatch, params.id, page, fetchSearchValue]);

  useEffect(() => {
    if (params.id && isSuccess) {
      dispatch(
        getEnterpriseCompany({
          service_id: params.id,
          currentPage: 1,
          limit: pageLimit,
          keyword: fetchSearchValue,
        })
      );
      dispatch(getDetail({ service: params.id }));

      setTimeout(() => {
        dispatch(removeSuccess());
      }, 1200);

      setTimeout(() => {
        setStatus(null);
      }, 2000);
    }
  }, [dispatch, params.id, isSuccess, fetchSearchValue, page]);

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        dispatch(removeSuccess());
        setStatus(null);
      }, 1500);
    }
  }, [dispatch, error]);

  const restart = () => {
    setFetchSearchValue('');
    let parameters = {
      service_id: params.id,
      keyword: '',
      page: page + 1,
      limit: pageLimit,
    };
    getEnterpriseCompany(parameters);
  };

  const handleStartService = () => {
    if (
      auth?.workspace_permissions?.length > 0 &&
      permissions?.find((item) => item.includes(detail?.sys_alias) && item.includes('enterprise.activate_services_enterprise_bought')) &&
      status.activate === false
    ) {
      const postObj = {
        enterprise_id: status.enterprise_id,
        activation_setting: !status.activate,
        service_id: parseInt(params.id),
      };
      dispatch(activateEnterpriseCompany(postObj));
      setIsOpen(false);
    }

    if (
      auth?.workspace_permissions?.length > 0 &&
      permissions?.find((item) => item.includes(detail?.sys_alias) && item.includes('enterprise.stop_services_enterprise_bought')) &&
      status.activate === true
    ) {
      const postObj = {
        enterprise_id: status.enterprise_id,
        activation_setting: !status.activate,
        service_id: parseInt(params.id),
      };
      dispatch(activateEnterpriseCompany(postObj));
      setIsOpen(false);
    }
  };

  const handleListDetail = (i) => {
    setIndex(i);
    if (i === index && openDetail === true) setOpenDetail(false);
    else setOpenDetail(true);
  };

  useEffect(() => {
    switch (tabValue) {
      case 0:
        return navigate('/dashboard/business/' + params.id);
      case 1:
        return navigate('/dashboard/plan-management/' + params.id);
      case 2:
        return navigate('/dashboard/payment/' + params.id);
      default:
        return null;
    }
  }, [tabValue, navigate, params.id]);

  const handlePageChange = (e) => {
    setPage(parseInt(e));
    dispatch(
      getEnterpriseCompany({
        service_id: params.id,
        currentPage: e,
        limit: pageLimit,
      })
    );
  };

  const handleDay = () => {
    const startDay = new Date(openTrialPlanPopup.start_date);
    const endDay = new Date(openTrialPlanPopup.expiration_date);
    const daysDiffA = Math.floor((+endDay - +startDay) / (1000 * 60 * 60 * 24)) + 1;
    return daysDiffA > 0 ? daysDiffA : '-';
  };

  const handleVerifyCompany = () => {
    setCompany('');
    dispatch(putVerifyEmail({ enterprise_id: company?.enterprise_id })).then((e) => {
      restart();
    });
  };

  const handleRemoveCompany = () => {
    if (generateCode === secretCode) {
      setErrorCode(false);
      dispatch(removeCompany({ enterprise_id: openRemove?.enterprise_id })).then((e) => {
        if (e.payload.msg === 'success') {
          restart();
        }
      });
      setOpenRemove('');
    } else {
      setErrorCode(true);
    }
  };

  return (
    <InnerLayout>
      <div className={styles.dashboard}>
        <div className={styles.dashboardBusinessRight}>
          <div className={styles.dashboardTitle}>{detail?.name}</div>
          <div className={styles.tabsContainer}>
            <Tabs values={tabList} onChange={(e) => setTabValue(e.tabIndex)} selectedIndex={parseInt(tabValue)} />
          </div>

          {auth?.workspace_permissions?.length > 0 &&
          permissions?.find((item) => item.includes(detail?.sys_alias) && item.includes('enterprise.view_enterprise_list')) &&
          !permission_error ? (
            <>
              <div className={styles.dashboardListContainer}>
                <div className={styles.dashboardListTitle}>企業帳號列表</div>
                <div className={styles.searchBar}>
                  <InputSearch
                    disabled={fetchSearchValue === '' && business?.totalData === 0}
                    placeholder='搜尋公司名稱或統一編號'
                    value={searchTerm}
                    onKeyPress={(e) => {
                      if ((e.code === 'Enter' || e.charCode === 13) && !loading) {
                        setFetchSearchValue(searchTerm);
                        setPage(0);
                      }
                    }}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    clickSearchIcon={() => {
                      if (!loading) {
                        setFetchSearchValue(searchTerm);
                        setPage(0);
                      }
                    }}
                    onClear={() => {
                      restart();
                      setSearchTerm('');
                    }}
                    width={'230px'}
                  />
                </div>
              </div>

              {!isGiftSuccess && isSuccess && (
                <Toast
                  show={isSuccess || error}
                  title={
                    error
                      ? `未付款，無法開通「${detail?.name}」`
                      : !status?.activate
                      ? `已成功開通「${detail?.name}」`
                      : status?.activate
                      ? `已成功停用「${detail?.name}」`
                      : null
                  }
                  subtitle={status?.enterprise_name}
                />
              )}

              <Alert
                isOpen={isOpen}
                onConfirm={handleStartService}
                onClose={() => {
                  setIsOpen(false);
                  setStatus(null);
                }}
                rightButtonText={status?.activate ? '停用' : '啟用'}
                leftButtonText='取消'
                mainTitle={status?.activate ? `停用「${detail?.name}於KEYSTONE系統」` : `啟用「${detail?.name}於KEYSTONE系統」`}
              />

              <div className={styles.tableContainer}>
                <table>
                  <thead>
                    <tr>
                      <th>公司名稱</th>
                      <th>統一編號</th>
                      <th style={{ minWidth: '90px' }}>驗證</th>
                      <th>開通狀況</th>
                      <th>試用設定</th>
                      <th>購買方案</th>
                      <th>帳號狀態</th>
                      <th>啟用日期</th>
                      <th>結束日期</th>
                      {/* <th>最後登入時間</th> */}
                      <th>已贈送帳號</th>
                      <th className={styles.center}>使用者名單</th>
                      <th width='10%'></th>
                    </tr>
                  </thead>
                  {loading ? (
                    <LoadingTable row={10} column={12} />
                  ) : (
                    <>
                      {business?.enterprise?.length > 0 ? (
                        <tbody>
                          {business?.enterprise?.map((data, i) => (
                            <React.Fragment key={i}>
                              <tr>
                                <td className={styles.tableTd}>
                                  <div className={styles.flex}>{data.enterprise_name}</div>
                                </td>
                                <td className={styles.tableTd}>{data.tax_id}</td>
                                <td className={styles.tableTd}>
                                  {data.email_verified_at ? (
                                    <Tips detail={`${moment(data.email_verified_at).format('YYYY/MM/DD')} Email已驗證`} topDiff={3} leftDiff={20}>
                                      <Button
                                        type='primary'
                                        small
                                        title={'已驗證'}
                                        icon={<FiCheck className={styles.emailVerified} />}
                                        color={'#34C759'}
                                      />
                                    </Tips>
                                  ) : (
                                    <Tips detail={`驗證企業帳號`} topDiff={3} leftDiff={33}>
                                      <Button type='primary' title={'驗證'} color={'#cccccc'} onClick={() => setCompany(data)} />
                                    </Tips>
                                  )}
                                </td>
                                <td className={styles.tableTd}>
                                  <AlertSwitch
                                    onChange={() => {
                                      setIsOpen(true);
                                      setStatus(data);
                                    }}
                                    color={{ on: '#5856D6', off: '#5856D64D' }}
                                    isChecked={data.activate}
                                  />
                                </td>
                                <td className={styles.tableTd}>
                                  <span className={styles.trial}>
                                    <span className={`${styles.trialState} ${data.is_trial ? styles.notPurchased : ''}`}>Free</span>
                                    <Button
                                      type='pure'
                                      disabled={!data.is_trial && data?.plan?.plan}
                                      icon={<AiOutlineSetting />}
                                      onClick={() => {
                                        setOpenGiftPopup('');
                                        if (data.is_trial) {
                                          setOpenTrialPlanPopup(data);
                                        } else {
                                          setOpenTrialPlanPopup('');
                                          setOpenTrialPlanModal(data);
                                        }
                                      }}
                                    />
                                    {openTrialPlanPopup && openTrialPlanPopup.enterprise_id === data.enterprise_id && (
                                      <span className={styles.trialPlanPopup}>
                                        <span className={styles.trialPlanPopupTop}>
                                          試用方案
                                          <Button type='tertiary' icon={<MdClear />} onClick={() => setOpenTrialPlanPopup('')} />
                                        </span>
                                        <span className={styles.trialPlanPopupMid}>
                                          <span className={styles.trialPlanPopupMidRow}>
                                            <span className={styles.trialPlanPopupMidRowTitle}>試用方案</span>
                                            <span className={styles.trialPlanPopupMidRowValue}>{openTrialPlanPopup.plan.name}</span>
                                          </span>
                                          <span className={styles.trialPlanPopupMidRow}>
                                            <span className={styles.trialPlanPopupMidRowTitle}>試用人數上限</span>
                                            <span className={styles.trialPlanPopupMidRowValue}>{openTrialPlanPopup.users_count_upper_limit}</span>
                                          </span>
                                          <span className={styles.trialPlanPopupMidRow}>
                                            <span className={styles.trialPlanPopupMidRowTitle}>試用時間</span>
                                            <span className={styles.trialPlanPopupMidRowValue}>
                                              {`${openTrialPlanPopup.start_date} 至 ${openTrialPlanPopup.expiration_date}`}
                                            </span>
                                          </span>
                                          <span className={styles.trialPlanPopupMidRowTip}>試用天數為{handleDay()}</span>
                                        </span>
                                        <div className={styles.trialPlanPopupBottom}>
                                          <Button title='關閉' type='secondary' onClick={() => setOpenTrialPlanPopup('')} />
                                          <Button
                                            title='編輯試用'
                                            type='primary'
                                            onClick={() => {
                                              setOpenTrialPlanModal(openTrialPlanPopup);
                                              setOpenTrialPlanPopup('');
                                            }}
                                          />
                                        </div>
                                      </span>
                                    )}
                                  </span>
                                </td>
                                <td className={styles.tableTd}>
                                  <span className={styles[data?.plan?.plan?.split('.')[2] || '-']}>{data?.plan?.plan?.split('.')[2] || '-'}</span>
                                </td>
                                <td className={styles.tableTd}>
                                  {data.subscription_status === 'subscribing' && <span className={`${styles.giftState}`}>訂閱中</span>}
                                  {data.subscription_status === 'on_trial' && <span className={`${styles.giftState}`}>試用中</span>}
                                  {data.subscription_status === 'no_purchase' && (
                                    <span className={`${styles.giftState} ${styles.notPurchased}`}>未購買</span>
                                  )}
                                  {data.subscription_status === 'no_renew' && (
                                    <span className={`${styles.giftState} ${styles.notPurchased}`}>未續約</span>
                                  )}
                                </td>
                                <td className={styles.tableTd}>{data.start_date}</td>
                                <td className={styles.tableTd}>{data.stop_at?.split(' ')[0]}</td>
                                <td className={styles.tableTd}>
                                  <span className={`${styles.trial} ${styles.gift}`}>
                                    <span>{data.gift_people ? data.gift_people + ' 人' : '-'}</span>
                                    <Button
                                      disabled={data.subscription_status !== 'subscribing'}
                                      type='pure'
                                      icon={<AiOutlineSetting />}
                                      onClick={() => {
                                        setOpenGiftPopup(data);
                                        setOpenTrialPlanPopup('');
                                        dispatch(getGift({ enterprise_id: data.enterprise_id, service_id: data?.plan?.software_service_id }));
                                      }}
                                    />
                                    {openGiftPopup && openGiftPopup.enterprise_id === data.enterprise_id && (
                                      <span className={styles.trialPlanPopup}>
                                        <span className={styles.trialPlanPopupTop}>
                                          贈送帳號
                                          <Button type='tertiary' icon={<MdClear />} onClick={() => setOpenGiftPopup('')} />
                                        </span>
                                        <span className={styles.trialPlanPopupMid}>
                                          <span className={styles.trialPlanPopupMidRow}>
                                            <span className={styles.trialPlanPopupMidRowTitle}>已贈送人數</span>
                                            <span className={styles.trialPlanPopupMidRowValue}>
                                              {openGiftPopup?.gift?.applied_at ? openGiftPopup?.gift_people + ' 人' : '-'}
                                            </span>
                                          </span>
                                          <span className={styles.trialPlanPopupMidRow}>
                                            <span className={styles.trialPlanPopupMidRowTitle}>贈送時間</span>
                                            <span className={styles.trialPlanPopupMidRowValue}>
                                              {openGiftPopup?.gift?.applied_at ? openGiftPopup?.gift?.start_date : '-'}
                                            </span>
                                          </span>
                                          <hr className={styles.divider} />
                                          <span className={styles.trialPlanPopupMidRow}>
                                            <span className={styles.trialPlanPopupMidRowTitle}>預約贈送人數</span>
                                            <span className={styles.trialPlanPopupMidRowValue}>
                                              {openGiftPopup?.gift && !openGiftPopup?.gift?.applied_at ? openGiftPopup?.gift?.quantity + ' 人' : '-'}
                                            </span>
                                          </span>
                                          <span className={styles.trialPlanPopupMidRow}>
                                            <span className={styles.trialPlanPopupMidRowTitle}>贈送時間</span>
                                            <span className={styles.trialPlanPopupMidRowValue}>
                                              {openGiftPopup?.gift && !openGiftPopup?.gift?.applied_at ? openGiftPopup?.gift?.start_date : '-'}
                                            </span>
                                          </span>
                                          <div className={styles.trialPlanPopupBottom}>
                                            <Button title='關閉' type='secondary' onClick={() => setOpenGiftPopup('')} />
                                            <Button
                                              title='贈送帳號'
                                              type='primary'
                                              onClick={() => {
                                                setOpenGiftModal(openGiftPopup);
                                                setOpenGiftPopup('');
                                              }}
                                            />
                                          </div>
                                        </span>
                                      </span>
                                    )}
                                  </span>
                                </td>

                                <td className={`${styles.center} ${styles.tableTd}`}>
                                  <Button
                                    type='pure'
                                    icon={<MdPeople />}
                                    onClick={() => {
                                      setIsOpenModalUser(true);
                                      setEnterpriseId(data.enterprise_id);
                                    }}
                                  />
                                </td>
                                <td className={`${styles.dropLists} ${styles.tableTd}`}>
                                  <div className={styles.actionWrapper}>
                                    <Tips detail='查看訂閱購買紀錄'>
                                      <Button
                                        type='pure'
                                        icon={<RiHistoryLine />}
                                        onClick={() => {
                                          setIsOpenModal(true);
                                          setEnterpriseId(data.enterprise_id);
                                          setServiceId(params.id);
                                        }}
                                      />
                                    </Tips>

                                    <div>
                                      <Button
                                        type='pure'
                                        icon={<IoChevronDownOutline />}
                                        open={i === index && openDetail === true}
                                        onClick={() => handleListDetail(i)}
                                      />
                                    </div>

                                    <div>
                                      <Tips detail={!data.stop_at && data.plan ? '此企業帳號仍在訂閱<br />或試用中，無法刪除' : '刪除企業帳號'}>
                                        <Button
                                          disabled={!data.stop_at && data.plan}
                                          type='pure'
                                          icon={<LuTrash />}
                                          onClick={() => {
                                            setGenerateCode(generateRandomString(12));
                                            setSecretCode('');
                                            setOpenRemove(data);
                                            setErrorCode(false);
                                          }}
                                          color='#ff0000'
                                        />
                                      </Tips>
                                    </div>
                                  </div>
                                </td>
                              </tr>

                              {i === index && openDetail === true && (
                                <tr>
                                  <td colSpan='12'>
                                    <div className={styles.details}>
                                      <div className={styles.detailsListContainer}>
                                        <div className={styles.detailsListTitle}>電子郵件</div>
                                        <div className={styles.detailsList}>{data.email}</div>
                                      </div>
                                      <div className={styles.detailsListContainer}>
                                        <div className={styles.detailsListTitle}>公司電話</div>
                                        <div className={styles.detailsList}>
                                          {data.phone_country} {data.phone}
                                        </div>
                                      </div>
                                      <div className={styles.detailsListContainer}>
                                        <div className={styles.detailsListTitle}>聯繫人姓名</div>
                                        <div className={styles.detailsList}>{data.contact_name ? data.contact_name : '-'}</div>
                                      </div>
                                      <div className={styles.detailsListContainer}>
                                        <div className={styles.detailsListTitle}>聯繫人職稱</div>
                                        <div className={styles.detailsList}>{data.job}</div>
                                      </div>
                                      <div className={styles.detailsListContainer}>
                                        <div className={styles.detailsListTitle}>聯繫人性別</div>
                                        <div className={styles.detailsList}>
                                          {!data.contact_gender && '-'}
                                          {data.contact_gender === 'male' && '男性'}
                                          {data.contact_gender === 'female' && '女性'}
                                          {data.contact_gender === 'other' && '其他'}
                                        </div>
                                      </div>
                                      <div className={styles.detailsListContainer}>
                                        <div className={styles.detailsListTitle}>聯繫人電話</div>
                                        <div className={styles.detailsList}>
                                          {data.cellphone_country}
                                          {data.cell_phone}
                                        </div>
                                      </div>
                                      <div className={styles.detailsListContainer}>
                                        <div className={styles.detailsListTitle}>目前最高人數</div>
                                        <div className={styles.detailsList}>{data.users_count_upper_limit} 人</div>
                                      </div>
                                      <div className={styles.detailsListContainer}>
                                        <div className={styles.detailsListTitle}>使用人數</div>
                                        <div className={styles.detailsList}>{data.users_number} 人</div>
                                      </div>
                                      <div className={styles.detailsListContainer}>
                                        <div className={styles.detailsListTitle}>產業</div>
                                        <div className={styles.detailsList}>{data.industry}</div>
                                      </div>

                                      <div className={styles.detailsListContainer}>
                                        {data?.payment_method && (
                                          <>
                                            <div className={styles.detailsListTitle}>付款方式</div>
                                            <div className={styles.detailsList}>
                                              {data.payment_method}
                                              <div>
                                                {data.start_date} ~ {data.expiration_date} ({data?.plan?.validity_days}天)
                                              </div>
                                            </div>
                                          </>
                                        )}
                                      </div>

                                      <div className={`${styles.detailsListContainer} ${styles.first}`}>
                                        <div className={styles.detailsListTitle}>官網 Admin</div>
                                        <div className={styles.detailsList}>
                                          {data.admins?.map((admin, index) => (
                                            <div key={index}>
                                              {admin.contact_name} <small>[{admin.email}]</small>
                                            </div>
                                          ))}
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              )}
                            </React.Fragment>
                          ))}
                        </tbody>
                      ) : (
                        <tbody>
                          <tr>
                            <td colSpan={9}>
                              <EmptyBox caption={fetchSearchValue ? '目前尚無搜尋結果' : '目前沒有企業帳號管理'} />
                            </td>
                          </tr>
                        </tbody>
                      )}
                    </>
                  )}
                </table>

                {business?.enterprise?.length > 0 && (
                  <div className={styles.paginationContainer}>
                    <Pagination
                      onPageChange={(e) => setPage(e.selected)}
                      pageCount={page + 1}
                      totalData={business?.recordsTotal}
                      onInputChange={handlePageChange}
                      totalPages={Math.ceil(business?.recordsTotal / pageLimit)}
                      limit={pageLimit}
                    />
                  </div>
                )}
              </div>
            </>
          ) : (
            <div className={styles.tableContainer}>
              <EmptyBox caption={'您沒有權限'} />
            </div>
          )}
        </div>
      </div>

      {openTrialPlanModal && (
        <TrialPlanModal
          openTrialPlanModal={openTrialPlanModal}
          setOpenTrialPlanModal={setOpenTrialPlanModal}
          restart={() => {
            dispatch(
              getEnterpriseCompany({
                service_id: params.id,
                currentPage: page + 1,
                limit: pageLimit,
                keyword: fetchSearchValue,
              })
            );
          }}
        />
      )}

      <GiftModal
        openGiftModal={openGiftModal}
        setOpenGiftModal={setOpenGiftModal}
        restart={() => {
          dispatch(
            getEnterpriseCompany({
              service_id: params.id,
              currentPage: page + 1,
              limit: pageLimit,
              keyword: fetchSearchValue,
            })
          );
        }}
      />

      {isOpenModalUser && (
        <ModalUsers isOpen={isOpenModalUser} setIsOpen={setIsOpenModalUser} enterpriseId={enterpriseId} setEnterpriseId={setEnterpriseId} />
      )}

      {isOpenModal && (
        <ModalHistory
          isOpen={isOpenModal}
          setIsOpen={setIsOpenModal}
          enterpriseId={enterpriseId}
          setEnterpriseId={setEnterpriseId}
          serviceId={serviceId}
          setServiceId={setServiceId}
        />
      )}

      {company && (
        <Alert
          isOpen={company}
          onConfirm={handleVerifyCompany}
          onClose={() => setCompany('')}
          rightButtonText={'驗證'}
          leftButtonText='取消'
          mainTitle={`確認驗證${company?.enterprise_name}？`}
        />
      )}

      {openRemove && (
        <Alert
          isWarning={true}
          isOpen={openRemove}
          onConfirm={handleRemoveCompany}
          onClose={() => setOpenRemove('')}
          rightButtonText={'確認刪除'}
          leftButtonText='取消'
          mainTitle={`確認刪除「${openRemove?.enterprise_name}」帳號？`}
        >
          <div className={styles.formSubtitle}>請輸入確認代碼</div>
          <div className={styles.formGroup}>確認代碼： {generateCode}</div>
          <TextField
            placeholder={'請輸入確認代碼'}
            onChange={(e) => {
              setErrorCode(false);
              setSecretCode(e);
            }}
            fullWidth
            value={secretCode}
            error={errorCode && secretCode !== generateCode}
          />
          {errorCode && secretCode !== generateCode && <div className={styles.error}>代碼有錯誤</div>}
        </Alert>
      )}
    </InnerLayout>
  );
};

export default DashboardBusiness;
