import styles from './style/Dashboard.module.scss';
import Modal from '../Utilities/Modal';
import Button from '../Utilities/Button';
import TextField from '../Utilities/TextField';
import { MdClear } from 'react-icons/md';
import { useEffect, useState } from 'react';
import { format } from 'date-fns';
import Datepicker from '../Utilities/Datepicker/Datepicker';
import { useDispatch, useSelector } from 'react-redux';
import { postGift, selectEnterpriseCompany } from '../../store/slice/manager/enterpriseCompany';
import moment from 'moment';
import Alert from '../Utilities/Alert';
import Toast from '../Utilities/Toast';
import { removeSuccess } from '../../store/slice/manager/enterpriseCompany';
import Tips from '../Utilities/Tips';

const TrialPlanModal = ({ openGiftModal, setOpenGiftModal, restart }) => {
  const dispatch = useDispatch();
  const { isGiftSuccess } = useSelector(selectEnterpriseCompany);
  const [tempData, setTempData] = useState('');
  const [alertGift, setAlertGift] = useState(false);
  const [dateError, setDateError] = useState(false);
  const [maxUsers, setMaxUsers] = useState(0);
  const [planDate, setPlanDate] = useState({
    startDay: new Date(),
    endDay: openGiftModal?.expiration_date,
  });
  const [error, setError] = useState(false);
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (openGiftModal) {
      setPlanDate({
        startDay: openGiftModal?.gift?.start_date ? openGiftModal?.gift?.start_date : new Date(),
        endDay: openGiftModal?.expiration_date,
      });
      if (openGiftModal?.gift) setMaxUsers(openGiftModal?.gift?.quantity > 0 ? openGiftModal?.gift?.quantity : 0);
    }
  }, [openGiftModal]);

  const handleStartDay = (date) => {
    const startDay = new Date(date);
    return format(startDay.setDate(startDay.getDate() - 1), 'yyyy-MM-dd');
  };

  const handleDate = () => {
    const startDay = new Date(planDate.startDay);
    const endDay = new Date(planDate.endDay);
    endDay.setHours(0, 0, 0, 0);
    startDay.setHours(0, 0, 0, 0);
    return startDay.getTime() < endDay.getTime();
  };

  const handleGift = () => {
    if (maxUsers >= 0 && planDate.startDay !== '' && planDate.endDay !== '') {
      let data = {
        enterprise_id: tempData?.enterprise_id,
        service_id: tempData?.plan?.software_service_id,
        quantity: +maxUsers,
        start_date: moment(planDate?.startDay).format('YYYY-MM-DD'),
        expiration_date: moment(planDate?.endDay).format('YYYY-MM-DD'),
      };
      dispatch(postGift(data)).then((e) => {
        if (e.payload.msg === 'success') {
          restart();
          setTimeout(() => {
            dispatch(removeSuccess());
          }, 2000);
        }
      });
      setError(false);
      setAlertGift(false);
      setTempData('');
      setOpenGiftModal('');
    } else {
      setError(true);
    }
  };

  useEffect(() => {
    if (parseInt(openGiftModal?.users_number) > parseInt(openGiftModal?.users_count_upper_limit - +openGiftModal?.gift?.quantity + +maxUsers)) {
      setChecked(true);
    } else {
      setChecked(false);
    }
  }, [maxUsers, openGiftModal]);

  return (
    <>
      <Modal isOpen={openGiftModal} size='sm' height={`366px`} width={`550px`}>
        <div className={styles.trialPlanModal}>
          <div className={styles.trialPlanModalTop}>
            編輯預約帳號
            <Button
              type='tertiary'
              icon={<MdClear />}
              onClick={() => {
                setTempData('');
                setOpenGiftModal('');
              }}
            />
          </div>
          <div className={styles.divider} />
          <div className={styles.trialPlanModalMid}>
            <div className={`${styles.trialPlanModalMidTop} ${styles.fixedWidth}`}>
              <div className={styles.leftTitle}>
                目前使用人數 : {openGiftModal?.users_number} 人<br />
                目前最高人數 : {openGiftModal?.users_count_upper_limit} 人
              </div>
            </div>

            <div className={`${styles.trialPlanModalMidTop} ${styles.fixedWidth}`}>
              <div className={styles.leftTitle}>贈送人數</div>
              {checked ? (
                <Tips detail={'無法刪減，贈送帳號數不可低於目前啟用帳號數'} topDiff={5} leftDiff={10}>
                  <TextField
                    placeholder='請輸入'
                    width='100%'
                    numeric={true}
                    value={maxUsers >= 0 ? maxUsers : 0}
                    onChange={(e) => setMaxUsers(e)}
                    error={maxUsers < 0 && error}
                    min={0}
                  />
                </Tips>
              ) : (
                <TextField
                  placeholder='請輸入'
                  width='100%'
                  numeric={true}
                  value={maxUsers >= 0 ? maxUsers : 0}
                  onChange={(e) => setMaxUsers(e)}
                  error={maxUsers < 0 && error}
                  min={0}
                />
              )}
            </div>

            <div className={styles.trialPlanModalMidMid}>
              <div className={styles.leftTitle}>增送時間</div>
              <Datepicker
                selectDate={planDate?.startDay}
                setSelectDate={(e) => {
                  setDateError(false);
                  setPlanDate({ ...planDate, startDay: e });
                }}
                disableEvents={[
                  {
                    start: `${handleStartDay(new Date())}`,
                    end: `${
                      planDate?.endDay !== '' && planDate?.endDate !== undefined && planDate?.endDate !== null
                        ? handleDate()
                          ? planDate?.endDay
                          : '2100-12-31'
                        : '2100-12-31'
                    }`,
                  },
                ]}
                error={(error && !planDate?.startDay) || dateError}
              />
              至
              <Datepicker
                selectDate={planDate?.endDay}
                setSelectDate={(e) => {
                  setDateError(false);
                  setPlanDate({ ...planDate, endDay: e });
                }}
                disableEvents={[
                  {
                    start: `${planDate?.startDay !== '' ? handleStartDay(planDate?.startDay) : handleStartDay(new Date())}`,
                    end: `2100-12-31`,
                  },
                ]}
                error={(error && !planDate?.endDay) || dateError}
                disabled={true}
              />
            </div>
          </div>
          <div className={styles.divider} />
          <div className={styles.trialPlanModalBottom}>
            <Button
              title='取消'
              type='secondary'
              onClick={() => {
                setOpenGiftModal('');
                setTempData('');
              }}
            />
            <Button
              disabled={checked}
              title='完成'
              type='primary'
              onClick={() => {
                setTempData(openGiftModal);
                setOpenGiftModal('');
                setAlertGift(true);
              }}
            />
          </div>
        </div>
      </Modal>

      {alertGift && (
        <Alert
          isOpen={alertGift}
          onConfirm={handleGift}
          onClose={() => {
            setAlertGift(false);
            setOpenGiftModal(tempData);
          }}
          rightButtonText={'確認'}
          leftButtonText='取消'
          mainTitle={maxUsers > 0 ? `確認贈送${maxUsers}個帳號？` : `確認取消贈送帳號？`}
        />
      )}

      <Toast show={isGiftSuccess} title={'已完成贈送帳號'} subtitle={`共 ${maxUsers} 人`} error={false} />
    </>
  );
};
export default TrialPlanModal;
